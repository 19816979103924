/* eslint-disable camelcase */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Home from '@cleverrealestate/clever-components-v2/dist/components/Home';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import HomePageBackground from '../images/img_comps/backgrounds/HomePageBackground';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const BuyPage = () => {
  const data = useStaticQuery(graphql`
    {
      cosmicjsWebsiteMetadata {
        metadata {
          trustpilot_reviews {
            review_count
            aggregate_rating
          }
        }
      }
    }
  `);

  useUserIPGrabber();

  return (
    <Layout
      colorTransitionStart={50}
      shadowTransitionStart={50}
      colorTransitionLength={125}
      shadowTransitionLength={125}
    >
      <SEO
        pathname="/buy-with-clever/"
        title="Buy a Home & Save Thousands at Close"
        description={'Ready to find your dream home? Buy a home with Clever'
          + ' and save thousands on closing costs with a commission rebate.'
          + ' Clever helps home buyers nationwide save thousands.'}
      />
      <Home
        homePageType="buying"
        background={HomePageBackground}
        trustpilotReviews={data.cosmicjsWebsiteMetadata.metadata.trustpilot_reviews}
      />
    </Layout>
  );
};

export default BuyPage;
